import React, { useState, useEffect, useCallback, useRef } from "react";
import VideoEmbed from "./VideoEmbed"; // Adjust the path as necessary

function TechniqueDriller() {
  const [data, setData] = useState([]);
  const [filteredTechniques, setFilteredTechniques] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [selectedTechniqueTypes, setSelectedTechniqueTypes] = useState(["All"]);
  const [selectedTechnique, setSelectedTechnique] = useState(null);
  const [selectedOpenGuardTypes, setSelectedOpenGuardTypes] = useState(["All"]);
  const [openGuardSubTypes, setOpenGuardSubTypes] = useState([]);
  const techniqueTitleRef = useRef(null);

  useEffect(() => {
    fetch("/techniques.json")
      .then((response) => response.json())
      .then((data) => {
        setData(data.techniques || []);
        extractOpenGuardSubTypes(data.techniques || []);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setData([]); // Ensure data is always an array even if fetch fails
      });
  }, []);

  const extractOpenGuardSubTypes = (techniques) => {
    const subTypes = new Set();
    techniques.forEach((technique) => {
      if (technique.category === "Open Guard" && technique.openguardType) {
        subTypes.add(technique.openguardType);
      }
    });
    setOpenGuardSubTypes(Array.from(subTypes));
  };

  const filterTechniques = useCallback(() => {
    let filtered = data;

    if (selectedCategories.length > 0 && !selectedCategories.includes("All")) {
      filtered = filtered.filter((t) =>
        selectedCategories.includes(t.category)
      );
    }

    if (
      selectedOpenGuardTypes.length > 0 &&
      !selectedOpenGuardTypes.includes("All")
    ) {
      filtered = filtered.filter((t) =>
        selectedOpenGuardTypes.includes(t.openguardType)
      );
    }

    if (
      selectedTechniqueTypes.length > 0 &&
      !selectedTechniqueTypes.includes("All")
    ) {
      filtered = filtered.filter((t) =>
        selectedTechniqueTypes.includes(t.type)
      );
    }

    filtered = filtered.filter((t) => t.type !== "General");
    setFilteredTechniques(filtered || []);
  }, [
    data,
    selectedCategories,
    selectedTechniqueTypes,
    selectedOpenGuardTypes,
  ]);

  useEffect(() => {
    filterTechniques();
  }, [filterTechniques]);

  const randomiseTechnique = () => {
    if (filteredTechniques.length > 0) {
      const randomIndex = Math.floor(Math.random() * filteredTechniques.length);
      selectTechnique(filteredTechniques[randomIndex], "random");
    }
  };

  const handleCategoryChange = (category) => {
    if (category === "All") {
      setSelectedCategories(["All"]);
      setSelectedOpenGuardTypes(["All"]);
    } else {
      let updatedCategories = selectedCategories.includes(category)
        ? selectedCategories.filter((c) => c !== category)
        : [...selectedCategories.filter((c) => c !== "All"), category];

      if (updatedCategories.length === 0) {
        updatedCategories = ["All"];
        setSelectedOpenGuardTypes(["All"]);
      }

      setSelectedCategories(updatedCategories);
    }
  };

  const handleTechniqueTypeChange = (type) => {
    if (type === "All") {
      setSelectedTechniqueTypes(["All"]);
    } else {
      let updatedTypes = selectedTechniqueTypes.includes(type)
        ? selectedTechniqueTypes.filter((t) => t !== type)
        : [...selectedTechniqueTypes.filter((t) => t !== "All"), type];

      if (updatedTypes.length === 0) {
        updatedTypes = ["All"];
      }

      setSelectedTechniqueTypes(
        updatedTypes.filter(
          (t) => t === "All" || activeTechniqueTypes.includes(t)
        )
      );
    }
  };

  const handleOpenGuardTypeChange = (type) => {
    if (type === "All") {
      setSelectedOpenGuardTypes(["All"]);
    } else {
      let updatedTypes = selectedOpenGuardTypes.includes(type)
        ? selectedOpenGuardTypes.filter((t) => t !== type)
        : [...selectedOpenGuardTypes.filter((t) => t !== "All"), type];

      if (updatedTypes.length === 0) {
        updatedTypes = ["All"];
      }

      setSelectedOpenGuardTypes(updatedTypes);
    }
  };

  const getActiveTechniqueTypes = () => {
    const activeTypes = new Set();
    const pinCategories = ["Side Control", "Mount", "Back"];
    const guardCategories = ["Closed Guard", "Half Guard", "Open Guard"];
    const standingCategories = ["Standing"];

    const onlyPinsSelected =
      selectedCategories.every((category) =>
        pinCategories.includes(category)
      ) && selectedCategories.length > 0;

    const onlyStandingSelected =
      selectedCategories.every((category) =>
        standingCategories.includes(category)
      ) && selectedCategories.length > 0;

    const onlyGuardsSelected =
      selectedCategories.every((category) =>
        guardCategories.includes(category)
      ) && selectedCategories.length > 0;

    if (onlyPinsSelected) {
      activeTypes.add("Escape");
      activeTypes.add("Submission");
      activeTypes.add("Progression");
      activeTypes.add("Maintenance");
      activeTypes.add("Survival");
    } else if (onlyGuardsSelected) {
      activeTypes.add("Pass");
      activeTypes.add("Sweep");
      activeTypes.add("Submission");
      activeTypes.add("Progression");
      activeTypes.add("Maintenance");
    } else if (onlyStandingSelected) {
      activeTypes.add("Takedown");
      activeTypes.add("Throw");
    } else {
      activeTypes.add("Escape");
      activeTypes.add("Submission");
      activeTypes.add("Progression");
      activeTypes.add("Pass");
      activeTypes.add("Sweep");
      activeTypes.add("Throw");
      activeTypes.add("Takedown");
      activeTypes.add("Maintenance");
      activeTypes.add("Survival");
    }

    return Array.from(activeTypes);
  };

  const activeTechniqueTypes = getActiveTechniqueTypes();

  useEffect(() => {
    const updatedTypes = selectedTechniqueTypes.filter(
      (type) => type === "All" || activeTechniqueTypes.includes(type)
    );

    if (updatedTypes.length === 0) {
      setSelectedTechniqueTypes(["All"]);
    } else if (
      JSON.stringify(updatedTypes) !== JSON.stringify(selectedTechniqueTypes)
    ) {
      setSelectedTechniqueTypes(updatedTypes);
    }

    if (selectedTechnique && !filteredTechniques.includes(selectedTechnique)) {
      setSelectedTechnique(null);
    }
  }, [
    activeTechniqueTypes,
    selectedTechniqueTypes,
    filteredTechniques,
    selectedTechnique,
  ]);

  const selectTechnique = (technique, selectMethod) => {
    setSelectedTechnique(technique);
    if (techniqueTitleRef.current && selectMethod !== "random") {
      techniqueTitleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="checkbox-group">
        <div className="checkbox-button">
          <input
            type="checkbox"
            id="all-category"
            value="All"
            checked={selectedCategories.includes("All")}
            onChange={() => handleCategoryChange("All")}
          />
          <label htmlFor="all-category">All</label>
        </div>
        {[
          "Side Control",
          "Mount",
          "Back",
          "Closed Guard",
          "Half Guard",
          "Open Guard",
          "Standing",
        ].map((category) => (
          <div key={category} className="checkbox-button">
            <input
              type="checkbox"
              id={`category-${category}`}
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={() => handleCategoryChange(category)}
            />
            <label htmlFor={`category-${category}`}>{category}</label>
          </div>
        ))}
      </div>

      {/* Open Guard Subtypes Row */}
      {selectedCategories.length === 1 &&
        selectedCategories.includes("Open Guard") &&
        openGuardSubTypes.length > 0 && (
          <div className="checkbox-group">
            <div className="checkbox-button">
              <input
                type="checkbox"
                id="all-openguard"
                value="All"
                checked={selectedOpenGuardTypes.includes("All")}
                onChange={() => handleOpenGuardTypeChange("All")}
              />
              <label htmlFor="all-openguard">All</label>
            </div>
            {openGuardSubTypes.map((type) => (
              <div key={type} className="checkbox-button">
                <input
                  type="checkbox"
                  id={`openguard-${type}`}
                  value={type}
                  checked={selectedOpenGuardTypes.includes(type)}
                  onChange={() => handleOpenGuardTypeChange(type)}
                />
                <label htmlFor={`openguard-${type}`}>{type}</label>
              </div>
            ))}
          </div>
        )}

      <div className="checkbox-group">
        <div className="checkbox-button">
          <input
            type="checkbox"
            id="all-technique"
            value="All"
            checked={selectedTechniqueTypes.includes("All")}
            onChange={() => handleTechniqueTypeChange("All")}
          />
          <label htmlFor="all-technique">All</label>
        </div>
        {[
          "Maintenance",
          "Progression",
          "Sweep",
          "Submission",
          "Pass",
          "Survival",
          "Escape",
          "Throw",
          "Takedown",
        ].map((type) => (
          <div key={type} className="checkbox-button">
            <input
              type="checkbox"
              id={`technique-${type}`}
              value={type}
              checked={selectedTechniqueTypes.includes(type)}
              onChange={() => handleTechniqueTypeChange(type)}
              disabled={!activeTechniqueTypes.includes(type)}
            />
            <label htmlFor={`technique-${type}`}>{type}</label>
          </div>
        ))}
      </div>

      <div className="checkbox-group">
        <button className="random-button" onClick={randomiseTechnique}>
          Random
        </button>
      </div>

      {selectedTechnique ? (
        <div className="selected-technique">
          <h2 ref={techniqueTitleRef} className="technique-name">
            {selectedTechnique.name}
          </h2>
          <h3 className="technique-alt-name">{selectedTechnique.jpEn}</h3>
          <h3 className="technique-alt-name">{selectedTechnique.altName}</h3>
          <p className="breadcrumb">{`${selectedTechnique.category} > ${selectedTechnique.type}`}</p>
          <p className="description">{selectedTechnique.description}</p>

          <ul>
            {selectedTechnique.steps.map((step, index) => (
              <li className="step" key={index}>
                {step}
              </li>
            ))}
          </ul>

          {(selectedTechnique.videoId || selectedTechnique.playlistId) && (
            <VideoEmbed
              videoId={selectedTechnique.videoId}
              playlistId={selectedTechnique.playlistId}
            />
          )}
        </div>
      ) : (
        <div className="holding-message">
          Select a technique to view details
        </div>
      )}

      <div>
        <h3>Techniques</h3>
        <div className="techniques-list">
          {filteredTechniques.length === 0 && <p>Nothing here yet!</p>}
          {filteredTechniques.map((tech, index) => (
            <button
              key={index}
              className={`technique-button ${
                selectedTechnique &&
                selectedTechnique.name === tech.name &&
                selectedTechnique.category === tech.category // Also check the category
                  ? "selected"
                  : ""
              }`}
              onClick={() => selectTechnique(tech)}
            >
              <strong>{tech.name}</strong>
              <div className="technique-type">
                {" "}
                {tech.category + " > " + tech.type}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TechniqueDriller;
