import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="disclaimer">
          This web app is a constant work in progress. Some content was
          AI-generated and may not be accurate. Video playlists are reviewed and
          updated over time. Please verify with a qualified instructor before
          attempting any techniques.
        </div>
        <a href="https://www.oweng.co.uk" target="_blank" rel="noreferrer">
          <img className="img" src="img/owenface.png" alt="logo" />
          <div>Made by Owen G</div>
        </a>
      </div>
    </>
  );
}
