import React from "react";

const VideoEmbed = ({ videoId, googleDriveUrl, playlistId }) => {
  const getEmbedUrl = () => {
    if (googleDriveUrl) {
      const driveEmbedUrl = googleDriveUrl.replace("/view", "/preview");
      return driveEmbedUrl;
    } else if (playlistId) {
      return `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
    } else if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return "";
  };

  const embedUrl = getEmbedUrl();

  if (!embedUrl) return null;

  return (
    <div className="video-container">
      <iframe
        src={embedUrl}
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoEmbed;
