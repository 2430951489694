import React, { useEffect, useState } from "react";

// Example JSON data
const jsonData = [
  {
    type: "connector",
    from: "closed guard",
    to: "diamond guard",
  },
  {
    type: "guard",
    name: "closed guard",
  },
  {
    type: "guard",
    name: "diamond guard",
  },
  {
    type: "sweep",
    name: "scissor sweep",
  },
  {
    type: "connector",
    from: "closed guard",
    to: "scissor sweep",
    comment: "This is a sweep from closed guard",
  },
];

const FlowChart = () => {
  const [nodes, setNodes] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [positions, setPositions] = useState({});

  useEffect(() => {
    const parsedNodes = [];
    const parsedConnectors = [];

    jsonData.forEach((item) => {
      if (item.type !== "connector") {
        parsedNodes.push({ name: item.name, type: item.type });
      } else if (item.type === "connector") {
        parsedConnectors.push({ from: item.from, to: item.to });
      }
    });

    setNodes(parsedNodes);
    setConnectors(parsedConnectors);
    calculatePositions(parsedNodes, parsedConnectors);
  }, []);

  const calculatePositions = (nodes, connectors) => {
    const nodePositions = {};
    const gridSpacing = 200; // Adjust spacing between nodes
    const grid = {}; // To keep track of used positions

    nodes.forEach((node, index) => {
      let x = 0;
      let y = index * gridSpacing;

      // Check for occupied positions and adjust
      while (grid[`${x},${y}`]) {
        x += gridSpacing;
      }

      nodePositions[node.name] = { x, y };
      grid[`${x},${y}`] = true;
    });

    setPositions(nodePositions);
  };

  return (
    <div
      className="flowchart-container"
      style={{
        position: "relative",
        height: `${
          Math.max(...Object.values(positions).map((pos) => pos.y)) + 200
        }px`,
        width: `${
          Math.max(...Object.values(positions).map((pos) => pos.x)) + 400
        }px`,
        border: "1px solid black",
      }}
    >
      {nodes.map((node, index) => (
        <div
          key={index}
          className="node"
          style={{
            position: "absolute",
            top: `${positions[node.name]?.y}px`,
            left: `${positions[node.name]?.x}px`,
            width: "150px",
            height: "50px",
            backgroundColor: node.type === "guard" ? "lightgrey" : "lightblue",
            border: "1px solid black",
            textAlign: "center",
            lineHeight: "50px",
          }}
        >
          {node.name}
        </div>
      ))}
      <svg
        className="connectors"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {connectors.map((connector, index) => {
          const fromPos = positions[connector.from];
          const toPos = positions[connector.to];

          if (!fromPos || !toPos) return null;

          const midX = (fromPos.x + toPos.x) / 2;

          return (
            <g key={index}>
              <line
                x1={fromPos.x + 150}
                y1={fromPos.y + 25}
                x2={midX}
                y2={fromPos.y + 25}
                stroke="black"
                strokeWidth="2"
              />
              <line
                x1={midX}
                y1={fromPos.y + 25}
                x2={midX}
                y2={toPos.y + 25}
                stroke="black"
                strokeWidth="2"
              />
              <line
                x1={midX}
                y1={toPos.y + 25}
                x2={toPos.x}
                y2={toPos.y + 25}
                stroke="black"
                strokeWidth="2"
              />
            </g>
          );
        })}
      </svg>
    </div>
  );
};

export default FlowChart;
