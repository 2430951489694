import React, { useState, useEffect, useRef } from "react";
import VideoEmbed from "./VideoEmbed";
import "../css/models.css";

function BJJModelsGrid() {
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedModel, setSelectedModel] = useState(null);

  // Create a ref for the model title
  const modelTitleRef = useRef(null);

  useEffect(() => {
    // Fetch the models.json file
    fetch("/models.json")
      .then((response) => response.json())
      .then((data) => {
        setModels(data);
        setFilteredModels(data); // Initialize with all models
      })
      .catch((error) => {
        console.error("Error loading models:", error);
        setModels([]); // Ensure models is always an array even if fetch fails
      });
  }, []);

  useEffect(() => {
    setSelectedModel(filteredModels[0]);
  }, [filteredModels]);

  const handleTypeChange = (type) => {
    setSelectedType(type);

    if (type === "All") {
      setFilteredModels(models);
    } else {
      setFilteredModels(models.filter((model) => model.type === type));
    }
  };

  const randomiseModel = () => {
    if (filteredModels.length > 0) {
      const randomIndex = Math.floor(Math.random() * filteredModels.length);
      selectModel(filteredModels[randomIndex]);
    }
  };

  const selectModel = (model) => {
    setSelectedModel(model);
    if (modelTitleRef.current) {
      modelTitleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="checkbox-group">
        <div className="checkbox-button">
          <input
            type="checkbox"
            id="all-type"
            value="All"
            checked={selectedType === "All"}
            onChange={() => handleTypeChange("All")}
          />
          <label htmlFor="all-type">All</label>
        </div>

        <div className="checkbox-button">
          <input
            type="checkbox"
            id="mechanical-type"
            value="Mechanical"
            checked={selectedType === "mechanical models"}
            onChange={() => handleTypeChange("mechanical models")}
          />
          <label htmlFor="mechanical-type">Mechanical</label>
        </div>
        <div className="checkbox-button">
          <input
            type="checkbox"
            id="strategic-type"
            value="Strategic"
            checked={selectedType === "strategic models"}
            onChange={() => handleTypeChange("strategic models")}
          />
          <label htmlFor="strategic-type">Strategic</label>
        </div>
      </div>

      <div className="checkbox-group">
        <button className="random-button" onClick={randomiseModel}>
          Random
        </button>
      </div>

      {/* Display Selected Model */}
      {selectedModel && (
        <div className="selected-model">
          <h2 ref={modelTitleRef}>{selectedModel.name}</h2>
          <p className="description">{selectedModel.description}</p>
          <p className="summary">{selectedModel.summary}</p>

          {/* Video Embed */}
          {(selectedModel.videoId || selectedModel.playlistId) && (
            <VideoEmbed
              videoId={selectedModel.videoId}
              playlistId={selectedModel.playlistId}
            />
          )}

          <a
            href={selectedModel.link}
            target="_blank"
            rel="noopener noreferrer"
            className="learn-more-button"
          >
            Learn more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </a>
        </div>
      )}

      <div className="models-grid">
        {filteredModels.map((model, index) => (
          <button
            key={index}
            className={`model-button ${
              selectedModel && selectedModel.name === model.name
                ? "selected"
                : ""
            }`}
            onClick={() => selectModel(model)}
          >
            <strong>{model.name}</strong>
          </button>
        ))}
      </div>
    </div>
  );
}

export default BJJModelsGrid;
