import React from "react";
import { Link, useLocation } from "react-router-dom";
export default function DRILLR() {
  const location = useLocation();
  return (
    <div className="header">
      <a href="/">
        <h1 className="drillr-logo">JitHub</h1>
        {/* <img className="logo-png" src="img/Only-Jits.png" alt="logo" /> */}
      </a>
      <div className="header-links">
        <Link to="/" className={location.pathname === "/" ? "active-link" : ""}>
          Techniques
        </Link>
        <Link
          to="/study"
          className={location.pathname === "/study" ? "active-link" : ""}
        >
          Study
        </Link>
        <Link
          to="/concepts"
          className={location.pathname === "/concepts" ? "active-link" : ""}
        >
          Concepts
        </Link>
      </div>
    </div>
  );
}
