import React, { useState } from "react";
import VideoEmbed from "./VideoEmbed";

function MyNotes({ lesson, allData, setAllData }) {
  // const [currentLesson, setCurrentLesson] = useState(lesson);
  const [currentLesson] = useState(lesson);
  const [chapters, setChapters] = useState(lesson.chapters || []);

  const handleNoteChange = (chapterIndex, noteIndex, newNote) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].notes[noteIndex] = newNote;
    setChapters(updatedChapters);
  };

  const handleTitleChange = (chapterIndex, newTitle) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].title = newTitle;
    setChapters(updatedChapters);
  };

  const handleTimeChange = (chapterIndex, newTime) => {
    const updatedChapters = [...chapters];
    updatedChapters[chapterIndex].time = newTime;
    setChapters(updatedChapters);
  };

  const addChapter = () => {
    setChapters([
      ...chapters,
      { title: "", time: "", notes: [""] }, // Add a new chapter with empty title and note
    ]);
  };

  const deleteChapter = (chapterIndex) => {
    const updatedChapters = chapters.filter(
      (_, index) => index !== chapterIndex
    );
    setChapters(updatedChapters);
  };

  const saveNotes = () => {
    const updatedData = allData.map((video) =>
      video.url === currentLesson.url ? { ...video, chapters } : video
    );
    setAllData(updatedData);
  };

  return (
    <div className="my-notes">
      <h3>Editing Notes for {currentLesson.lesson}</h3>

      {/* Display Video */}
      <VideoEmbed
        videoId={
          currentLesson.url.includes("youtube.com")
            ? currentLesson.url.split("v=")[1]
            : null
        }
        googleDriveUrl={
          currentLesson.url.includes("drive.google.com")
            ? currentLesson.url
            : null
        }
      />

      {/* Render existing chapters */}
      {chapters.map((chapter, chapterIndex) => (
        <div key={chapterIndex} className="chapter">
          <input
            type="text"
            value={chapter.title}
            onChange={(e) => handleTitleChange(chapterIndex, e.target.value)}
            placeholder="Chapter title"
          />
          <input
            type="text"
            value={chapter.time}
            onChange={(e) => handleTimeChange(chapterIndex, e.target.value)}
            placeholder="Chapter time"
          />
          <div className="notes">
            {chapter.notes.map((note, noteIndex) => (
              <textarea
                key={noteIndex}
                value={note}
                onChange={(e) =>
                  handleNoteChange(chapterIndex, noteIndex, e.target.value)
                }
              />
            ))}
          </div>
          <button onClick={() => deleteChapter(chapterIndex)}>
            Delete Chapter
          </button>
        </div>
      ))}

      {/* Button to add a new chapter */}
      <button onClick={addChapter}>Add New Chapter</button>

      {/* Button to save notes */}
      <button onClick={saveNotes}>Save Notes</button>
    </div>
  );
}

export default MyNotes;
